import { defineStore } from 'pinia';

export const useNewsStore = defineStore('newsStore', {
  state: () => ({
    list: null,
    meta: {
      page: 1,
      total: 0,
      per_page: 12,
    },
    item: null,
  }),
  actions: {
    async getList() {
      const { data: list } = await useFetch(`/api/v2/blog?page=${this.meta.page}&per_page=${this.meta.per_page}`);
      if (list && list.value && list.value.data && list.value.data !== null) this.list = list.value.data;
      if (list && list.value && list.value.last_page && list.value.last_page !== null) this.meta.total = list.value.last_page;
      if (list && list.value && list.value.current_page && list.value.current_page !== null) this.meta.page = list.value.current_page;
      if (list && list.value && list.value.per_page && list.value.per_page !== null) this.meta.per_page = list.value.per_page;
    },
    async getItem(payload) {
      const { data: item } = await useFetch(`/api/v2/blog/${payload.city}/${payload.slug}`);
      if (item && item !== null) this.item = item;
    }
  }
});